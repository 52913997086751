import styled from "styled-components";
import "./AppFooter.scss";
import { Box } from "../styled-system/Box";
import { NavigationLink } from "../styled-system/NavigationLink";
import { Paths } from "../../paths";
import { useEffect, useState } from "react";
import { getLastUpdated } from "../../api/old-vine-registry-api";
import { Image } from "react-bootstrap";
import Award from "../../images/award.png";
import { formatDate } from "../../utils/format";

const Divider = styled.div`
  height: 0;
  margin-top: 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
`;

const AwardImage = ({ src }: { src: string }) => (
  <div className="award-image">
      <Image src={src} fluid={true} />
  </div>
);

const Bullet = () => <div className="bullet" />;

function useLastUpdated(): string {
  const [lastUpdated, setLastUpdated] = useState("");
  useEffect(() => {
    getLastUpdated().then((date) => setLastUpdated(date));
  }, []);
  return lastUpdated;
}

export const AppFooter = () => {
  const lastUpdated = useLastUpdated();

  return (
    <div className="app-footer">
      <div className="footer-flex">
        <div className="text-container">
          <div className="navigation-panel app-footer-link">
            <NavigationLink to={Paths.feedback}>Provide feedback</NavigationLink>
            <Bullet />
            <NavigationLink to={Paths.submit}>Submit Vineyard</NavigationLink>
            <Bullet />
            <NavigationLink to={Paths.about}>About</NavigationLink>
            <Bullet />
            <NavigationLink to={Paths.faq}>FAQ</NavigationLink>
            <Bullet />
            <NavigationLink to={Paths.privacy}>Privacy Policy</NavigationLink>
            <Bullet />
            <a href="https://www.oldvines.org/checkout/donate?donatePageId=6462a1da9d9f251340b8f1b2" 
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none"}}>Donate</a>
            <Bullet />
            <NavigationLink to={Paths.contest}>Contest</NavigationLink>
          </div>

          <Box mt="20px" className="desktop-only">
            This site and its contents are a community-generated effort. Every
            attempt has been made to keep information accurate and up to date, but
            we cannot guarantee the accuracy of any information herein.
          </Box>

          <Box mt="16px" className="desktop-only">
            <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
              <img
                alt="Creative Commons License"
                style={{ borderWidth: 0 }}
                src="https://i.creativecommons.org/l/by-nc/4.0/80x15.png"
              />
            </a>
            <br />
            <span property="dct:title">The Old Vine Registry</span> by{" "}
            <a
              href="http://www.oldvineregistry.org"
              property="cc:attributionName"
              rel="cc:attributionURL"
            >
              The Old Vine Registry
            </a>{" "}
            is licensed under a{" "}
            <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
              Creative Commons Attribution-NonCommercial 4.0 International License
            </a>
            .<br />
            Based on a work at{" "}
            <a href="http://www.oldvineregistry.org" rel="dct:source">
              http://www.oldvineregistry.org
            </a>
          </Box>
        </div>
        <div className="image-container">
            <AwardImage src={Award} />
            <span className="form-label"
                  style={{ display: "block", textAlign: "center" }}>
                2024 Winner: Best Heritage Initiative
            </span>
        </div>
      </div>
      <Box mt="20px" className="mobile-only">
        This site and its contents are a community-generated effort. Every
        attempt has been made to keep information accurate and up to date, but
        we cannot guarantee the accuracy of any information herein.
      </Box>

      <Box mt="16px" className="mobile-only">
        <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
          <img
            alt="Creative Commons License"
            style={{ borderWidth: 0 }}
            src="https://i.creativecommons.org/l/by-nc/4.0/80x15.png"
          />
        </a>
        <br />
        <span property="dct:title">The Old Vine Registry</span> by{" "}
        <a
          href="http://www.oldvineregistry.org"
          property="cc:attributionName"
          rel="cc:attributionURL"
        >
          The Old Vine Registry
        </a>{" "}
        is licensed under a{" "}
        <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
          Creative Commons Attribution-NonCommercial 4.0 International License
        </a>
        .<br />
        Based on a work at{" "}
        <a href="http://www.oldvineregistry.org" rel="dct:source">
          http://www.oldvineregistry.org
        </a>
      </Box>
      <div>
      <Divider />
      <Box className="app-footer-updated">
        Database last updated: {formatDate(lastUpdated)}
      </Box>
      </div>
    </div>
  );
};
