import "./SearchTips.scss";
import { Box } from "../styled-system/Box";

export interface SearchTipsProps {
  className?: string;
}



export const SearchTips = ({
  className,
}: SearchTipsProps) => {
  
  return (
    <Box maxWidth="90rem" className={`description-text ${className || ""}`}>
      <div className="title-small">Search Tips</div>
      You can search by the following parameters: vineyard name, country,
      region, sub-region/appellation, grape variety, name of the owner, and name
      of a person of interest.
      <ul className="u-mt-medium u-ml-medium">
        <li>
          Most regions and appellations are rendered with their local spelling
          rather than being Anglicized (e.g. Cataluña vs Catalonia)
        </li>
        <li>
          Names from non-latin character sets have been transliterated to the
          best of our ability in conformance with best practices
        </li>
        <li>
          Many vineyard records are incomplete (e.g. do not list grape varieties
          or size). Help us correct these omissions!
        </li>
        <li>
          If you are interested in browsing lists of old vineyards by country or
          by age-range, use the dropdowns above.
        </li>
      </ul>
    </Box>
  );
};
