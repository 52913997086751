import "./Vineyard.scss";
import { BiChevronRight } from "react-icons/bi";
import { Box } from "../../components/styled-system/Box";
import { useEffect } from "react";
import { Media } from "react-breakpoints";
import { Image } from "react-bootstrap";
import { Divider } from "../../components/layout/Divider";
import styled from "styled-components";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Paths } from "../../paths";
import { Center } from "../../components/layout/Center";
import { useQuery } from "@tanstack/react-query";
import { getVineyard } from "../../api/old-vine-registry-api";

function acres(ha: string | undefined): string {
  const haNum = Number(ha);
  const haToAcres = 2.47105;
  

  if (isNaN(haNum)) {
    return "";
  }
  const fractionDigits = haNum > 10 ? 0 : 2;
  return `${(haNum * haToAcres).toFixed(fractionDigits)}`;
}

function feet(meters: string | undefined): string {
  const metersNum = Number(meters);
  const metersToFeet = 3.28084;
  
  if (isNaN(metersNum)) {
    return "";
  }
  const fractionDigits = metersNum > 10 ? 0 : 2;
  return `${(metersNum * metersToFeet).toFixed(fractionDigits)}`;
}

const HorFieldContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: 2rem;
  margin-top: 24px;
`;

const VertFieldContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
`;

const VertFieldContainer2 = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 4rem;
`;

const FieldBig = (props: { field: string; value: string }) => (
  <Box display="flex" flexDirection="column" minWidth="8rem">
    <span className="field-name">{props.field}</span>
    <span className="field-value">{props.value}</span>
  </Box>
);

const Field = (props: { label: string; value?: string }) => {
  return (
    <>
      <span className="field-name vineyard-field-name">{props.label}</span>
      <span className="description-text">{props.value}</span>
    </>
  );
};

const FieldHtml = (props: { label: string; value: string }) => {
  return (
      <>
        <span className="field-name vineyard-field-name">{props.label}</span>
        <span className="description-text"  ><div dangerouslySetInnerHTML={{__html: props.value}} /></span>
      </>
  );
};

export const Vineyard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let { data, isFetching } = useQuery({
    queryKey: ["vineyard"],
    queryFn: () => getVineyard((searchParams.get("id") || "")),
  });
  
  useEffect(() => {
    // Set the title when the component mounts if isFetching is false
    if (!isFetching) {
      document.title = data?.name + ', ' + data?.yearPlanted + ':  Old Vine Registry';

      // Set the meta description
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', 'Details about the old vineyard ' + data?.name 
                                                    + ' in ' + data?.region + ', ' + data?.country 
                                                    + ' owned by ' + data?.ownerOrProducerName 
                                                    + ', planted in ' + data?.yearPlanted + '.');
      }

      // Set the meta keywords
      const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
      if (metaKeywordsTag) {
        metaKeywordsTag.setAttribute('content', 'old vines, old vineyard, ' + data?.name 
                                                 + ', ' + data?.region + ', ' + data?.country
                                                 + ', ' + data?.varieties);
      }
    }
    
    // Clean up the title when the component unmounts
    return () => {
      // Reset the meta description
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', 'The Old Vine Registry, the world’s first crowd-sourced global database of living historic vineyard sites');
      }

      // Reset the meta keywords
      const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
      if (metaKeywordsTag) {
        metaKeywordsTag.setAttribute('content', 'old vines, old vineyard');
      }

      document.title = 'Old Vine Registry';
    };
  }, [isFetching, data]); // Empty dependency array ensures this effect runs only once
  
  if (isFetching) {
    return (<div style={{ minHeight: '800px' }}></div>);
  }

  const formattedName = data?.name?.replace(/ /g, "+");

  return (
    <div className="vineyard-layout">
      <div className="title-large">{data?.name || "Unknown"}</div>

      <Box display="flex" alignItems="center" mb="25px">
        <span className="search-link">
        <a
         href={`${Paths.vineyards}?search=${data?.country}`}
          target="_blank" // Open in a new tab
          rel="noopener noreferrer" // Added for security
          onClick={(e) => {
            e.preventDefault(); // Prevent the default link behavior
            navigate(`${Paths.vineyards}?search=${data?.country}`);
          }}>
          <span>
          {data?.country}
          </span>
        </a>

        <BiChevronRight size="1.6rem" />
          <a
          href={`${Paths.vineyards}?search=${data?.region}`}
            target="_blank" // Open in a new tab
            rel="noopener noreferrer" // Added for security
            onClick={(e) => {
              e.preventDefault(); // Prevent the default link behavior
              navigate(`${Paths.vineyards}?search=${data?.region}`);
            }}>
            <span>
            {data?.region}
            </span>
          </a>
          
          {data?.subRegion && (
            <>
              <BiChevronRight size="1.6rem" />
              <a
                href={`${Paths.vineyards}?search=${data?.subRegion}`}
                  target="_blank" // Open in a new tab
                  rel="noopener noreferrer" // Added for security
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default link behavior
                    navigate(`${Paths.vineyards}?search=${data?.subRegion}`);
                  }}>
                <span>
                {data?.subRegion}
                </span>
              </a>
            </>
          )}
          {data?.subSubRegion && (
              <>
                <BiChevronRight size="1.6rem" />
                <a
                  href={`${Paths.vineyards}?search=${data?.subSubRegion}`}
                    target="_blank" // Open in a new tab
                    rel="noopener noreferrer" // Added for security
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default link behavior
                      navigate(`${Paths.vineyards}?search=${data?.subSubRegion}`);
                    }}>
                  <span>
                  {data?.subSubRegion}
                  </span>
                </a>
              </>
          )}
        </span>
      </Box>

      {data?.preferredImage && <a href={data?.photoUrl}><Image src={data?.preferredImage} fluid={true} /></a>}
      {data?.photoCredit && <div>Photo of {data?.name} by <a href={"https://commons.wikimedia.org/wiki/User:"+data?.photoCredit}>{data?.photoCredit}</a></div>}

      <HorFieldContainer>
        <FieldBig
          field="Planted"
          value={`${data?.isEstimated ? "Est. " : ""} ${data?.yearPlanted}`}
        />
        <FieldBig
          field="Age of vines"
          value={`${data?.isEstimated ? "Est. " : ""} ${
            data?.ageOfVines
          } years`}
        />
        <FieldBig
          field="Size of planting"
          value={
            data?.sizeOfPlantingInHa
              ? +(data?.sizeOfPlantingInHa || 0) +
                " ha / " +
                (acres(data?.sizeOfPlantingInHa) || 0) + " acres"
              : "Unknown"
          }
        />
        <FieldBig
          field="Is own-rooted"
          value={data?.isOwnRooted ? "Yes" : "No"}
        />
      </HorFieldContainer>

      <Divider />

      <VertFieldContainer>
        <span className="field-name vineyard-field-name">Owner / Producer</span>
        {

        }
        <a
          href={data?.ownerOrProducerName && `${Paths.vineyards}?search=${data?.ownerOrProducerName}`}
            target="_blank" // Open in a new tab
            rel="noopener noreferrer" // Added for security
            onClick={(e) => {
              e.preventDefault(); // Prevent the default link behavior
              if (data?.ownerOrProducerName) {
                navigate(
                  `${Paths.vineyards}?search=${data?.ownerOrProducerName}`
                );
              }
            }}>
          <span className={`${
            data?.ownerOrProducerName
              ? "description-text-link"
              : "description-text"
          }`}>
            {data?.ownerOrProducerName || "Unknown"}
          </span>
        </a>

        <span className="field-name vineyard-field-name">URL</span>
        <Link to={data?.ownerOrProducerWebsite || ""} target="_blank">
          <span className="description-text-link">
            {data?.ownerOrProducerWebsite}
          </span>
        </Link>
        
        {data?.latitude && data?.longitude && (
          <>
            <span className="field-name vineyard-field-name">
              Coordinates
            </span>
            <span className="description-text">
              <Link to={data?.locationOverrideUrl ? data?.locationOverrideUrl : "https://earth.google.com/web/@" + data?.latitude + "," + data?.longitude+",451.96001927a,535.30900042d,35y,-0h,0t,0r"} target="_blank">
                <span className="description-text-link">
                  {data?.latitude + "," + data?.longitude}
                </span>
              </Link>
            </span>
            </>)
        }

        {data?.elevationInMeters && (
          <>
            <span className="field-name vineyard-field-name">
              Elevation
            </span>
            <span className="description-text">
               {data?.elevationInMeters
              ? +(data?.elevationInMeters || 0) +
                " meters / " +
                (feet(data?.elevationInMeters) || 0) + " feet"
              : "Unknown"} 
            </span>
            </>)
        }

        <span className="field-name vineyard-field-name">
          Person of interest
        </span>
        <span className="description-text">
          {data?.personOfInterest}
          {data?.personOfInterestRole && (
            <span className="person-of-interest-role">
              , {data?.personOfInterestRole}
            </span>
          )}
        </span>

        <FieldHtml label="Notes on dates" value={data ? data.dateNote : ''} />
        <Field label="Grape varieties" value={data?.varieties} />

        <span />
        {/*^^ Keep this span for formatting. It is a placeholder element in the grid layout. */}
        <span>
          <Link
            to={(data?.findWinesLink === "" || data?.findWinesLink === undefined) 
                        ? `http://www.wine-searcher.com/find/${formattedName}/ALL/EUR/A?referring_site=VIN` 
                        : data?.findWinesLink}
            target="_blank"
            className="button"
          >
            <Center>
              <Media>
                {({ currentBreakpoint }) => (
                  <>
                    {currentBreakpoint === "desktop"
                      ? "Find wines from this vineyard"
                      : "Find wines"}
                  </>
                )}
              </Media>
            </Center>
          </Link>
        </span>

        <FieldHtml label="Notes" value={data ? data.notes : ''} />
      </VertFieldContainer>

      <Divider />

      <VertFieldContainer2>
        <span className="field-name vineyard-field-name">Info Source</span>
        <span className="description-text"><div dangerouslySetInnerHTML={{__html: data ? data.informationSource : ''}} /></span>
        <span className="field-name vineyard-field-name">Confirmed by</span>
        <span className="description-text">{data?.confirmedByName}</span>
      </VertFieldContainer2>
    </div>
  );
};
