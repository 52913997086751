import "./SubmitPage.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import BsForm from "react-bootstrap/Form";
import * as Yup from "yup";
import { Box } from "../../components/styled-system/Box";
import { LocalDateTime } from "@js-joda/core";
import { Link, useNavigate } from "react-router-dom";
import { VineyardSubmission } from "../../api/models";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { submitVineyard } from "../../api/old-vine-registry-api";
import { Paths } from "../../paths";
import { FormInput } from "../../components/form/FormInput";
import { Label } from "../../components/form/Label";

const Loading = (props: { loading: boolean }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="25px"
    mt="20px"
  >
    {props.loading && <Spinner animation="border" role="status" />}
  </Box>
);

export const SubmitPage = () => {
  useEffect(() => {
    // Set the title when the component mounts
    document.title = 'Old Vine Registry Submit';
    
    // Clean up the title when the component unmounts
    return () => {
      document.title = 'Old Vine Registry';
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const year = LocalDateTime.now().minusYears(35).year();
  const years = Array.from({ length: 500 }, (_, i) => year - i).filter(
    (y) => y >= 1800
  );

  const navigate = useNavigate();

  const navigateToSubmission = () => {
      navigate('/faq#submission-section');
  };

  const [submitting, setSubmitting] = useState(false);
  
  const submit = (vineyard: VineyardSubmission) => {
    console.log("Size of Vineyard Before: ", vineyard.sizeOfVineyard);
    const sizeOfVineyardInput = vineyard.sizeOfVineyard?.toString() ?? "0";
    const elevationInput = vineyard.elevation?.toString() ?? "0";
    console.log("Size of Vineyard After: ", sizeOfVineyardInput);

    // Normalize the sizeOfVineyard property
    const normalizedVineyard = {
      ...vineyard,
      sizeOfVineyard: parseFloat(
        sizeOfVineyardInput
          .replace(/,/g, ".") // Replace commas with periods
          .replace(/[^0-9.]/g, "") // Remove invalid characters
      ),
      elevation: parseFloat(
        elevationInput
          .replace(/,/g, ".") // Replace commas with periods
          .replace(/[^0-9.]/g, "") // Remove invalid characters
      ),
    };


    setSubmitting(true);
    submitVineyard(normalizedVineyard)
      .then(() => {
        navigate(Paths.thankYou);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="submit-layout">
      <div className="title-large">Submit a vineyard</div>
      <p className="description-text">
        IMPORTANT: Please be sure you have carefully searched the database and
        are 100% certain that the vineyard you are about to submit does not
        exist in our records.  Please review the {" "} <span className="description-text-link" 
              onClick={navigateToSubmission}>Vineyard Submission FAQs</span> before making your submission. All
        submissions will be reviewed and verified by our editors before
        inclusion in the database. As this is a volunteer effort, it may take
        some time before your submission appears in the database. Thank you for
        your patience.
      </p>
      <p className="description-text">
      If you plan to submit more than one vineyard to the Registry, 
      you are encouraged to use this <a href="https://d.pr/f/LxfmaA">Excel spreadsheet</a> for your data entry, 
      which will be faster and easier than the web form. 
      Completed spreadsheets can be emailed to: <a href="mailto:oldvineregistry@gmail.com">oldvineregistry@gmail.com</a>.
      </p>
      <p className="description-text u-mt-large">* required fields</p>

      <Formik
        initialValues={
          {
            vineyardName: "",
            vineyardLocation: "",
            dateExplanation: "",
            isDateEstimated: false,
            plantedOnOwnRoots: undefined,
            sizeOfVineyard: undefined,
            sizeUnit: "ha",
            elevation: undefined,
            elevationUnit: "meters",
            varieties: "",
            ownerOrNameOfEstate: "",
            websiteOfEstate: "",
            personOfInterest: "",
            role: "",
            note: "",
            submitterName: "",
            submitterEmail: "",
          } as VineyardSubmission
        }
        validationSchema={Yup.object({
          vineyardName: Yup.string().required("Please provide a vineyard name"),
          vineyardLocation: Yup.string().required(
            "Please provide a vineyard location"
          ),
          yearPlanted: Yup.string().required("Please provide the year planted"),
          plantedOnOwnRoots: Yup.string().required(
            "Please select if planted on own roots"
          ),
          sizeOfVineyard: Yup.string()
            .required("Vineyard size required")
            .matches(/\d+[.,]?\d*/, {
              message: "Invalid size",
              excludeEmptyString: true,
            }),
          varieties: Yup.string().required(
            "Please provide the grape varieties"
          ),
          ownerOrNameOfEstate: Yup.string().required(
            "Please fill in the owner or estate name"
          ),
          websiteOfEstate: Yup.string()
            .nullable() // Allows null values
            .notRequired() // Makes the field optional
            .test(
              "is-valid-url",
              "Invalid website",
              (value) =>
                !value || // Allows empty values without validation
                /^https?:\/\/[^\s/$.?#].[^\s]*$/.test(value) || // Matches URLs with http/https
                /^[^\s/$.?#].[^\s]*\.[^\s]{2,}$/.test(value) // Matches domain-only URLs like www.example.com
            ),
          submitterName: Yup.string().required("Please enter your name"),
          submitterEmail: Yup.string()
            .required("Please enter your email address")
            .email("Invalid email address"),
        })}
        onSubmit={(values) => {
          console.log(values);
          submit(values);
        }}
      >
        {(formik) => (
          <Form>
            {formik.submitCount > 0 && !formik.isValid && (
              <span className="submit-error-heading">
                There was an issue with your submission. Please correct the
                fields marked below.
              </span>
            )}

            <Box display="flex" flexDirection="column" maxWidth="600px">
              <Label
                label="Vineyard name*"
                field="vineyardName"
                className="field-spacing"
              />
              <FormInput
                placeholder="Enter the vineyard name"
                name="vineyardName"
                type="text"
                autoComplete="off"
                className="input-text"
              />
              <ErrorMessage
                name="vineyardName"
                className="input-error"
                component="span"
              />
              <span className="form-fields-sub-text">
                Please provide the vineyard name that typically appears on wine
                labels. If it does not appear on wine labels, then provide the
                name used by its owners.
              </span>

              <Label
                field="vineyardLocation"
                label="Vineyard location*"
                className="field-spacing"
              />
              <FormInput
                placeholder="Appellation, Region, and Country"
                name="vineyardLocation"
                type="text"
                autoComplete="off"
                className="input-text"
              />
              <ErrorMessage
                name="vineyardLocation"
                className="input-error"
                component="span"
              />
              <span className="form-fields-sub-text">
                Please provide any known appellations or Geographical
                Indications, as well as the country.
              </span>

              <Label
                field="yearPlanted"
                label="Date planted*"
                className="field-spacing"
              />
              <Box display="flex" alignItems="center">
                <Box width="14rem" mr="1.6rem">
                  <Field name="yearPlanted">
                    {({ field, meta }) => (
                      <BsForm.Select
                        className={`input-text input-height ${
                          meta.error && meta.touched ? "input-field-error" : ""
                        }`}
                        {...field}
                      >
                        <option value="">Select year</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </BsForm.Select>
                    )}
                  </Field>
                </Box>
                <Field name="isDateEstimated">
                  {({ field }) => (
                    <BsForm.Check
                      label="This is an estimated date"
                      className="checkbox-label-text"
                      {...field}
                    />
                  )}
                </Field>
              </Box>
              <ErrorMessage
                name="yearPlanted"
                className="input-error"
                component="span"
              />
              <span className="form-fields-sub-text">
                Please select date. Every vineyard must have a planting date.
              </span>

              <Label
                field="dateExplanation"
                label="Date explanation"
                className="field-spacing"
              />
              <Field
                name="dateExplanation"
                as="textarea"
                rows="4"
                autoComplete="off"
                className="input-text"
              />
              <span className="form-fields-sub-text">
                Provide any firther context required for estimated or
                speculative dates.
              </span>

              <Box
                display="grid"
                alignItems="center"
                gridTemplateColumns="repeat(auto-fill, minmax(190px, 1fr))"
                className="field-spacing"
              >
                <Label
                  field="plantedOnOwnRoots"
                  label="Planted on its own roots?*"
                />
                <Box display="flex" alignItems="center">
                  <Field name="plantedOnOwnRoots">
                    {({ field }) => (
                      <BsForm.Check
                        type="radio"
                        label="No"
                        className="checkbox-label-text u-ml-medium"
                        {...field}
                        value={false}
                      />
                    )}
                  </Field>
                  <Field name="plantedOnOwnRoots">
                    {({ field }) => (
                      <BsForm.Check
                        type="radio"
                        label="Yes (some or all)"
                        className="checkbox-label-text u-ml-medium"
                        {...field}
                        value={true}
                      />
                    )}
                  </Field>
                </Box>
              </Box>
              <ErrorMessage
                name="plantedOnOwnRoots"
                className="input-error"
                component="span"
              />
              <span className="form-fields-sub-text">
                If some or all of the vineyard is own-rooted / franc de pied
                please provide information about this in the notes field below,
                including an estimate of how much of the vineyard area this
                represents.
              </span>

              <Label
                field="sizeOfVineyard"
                label="Size of vineyard*"
                className="field-spacing"
              />
              <Box display="flex">
                <div>
                  <Field name="sizeOfVineyard">
                    {({ field, meta }) => (
                      <input
                        type="text"
                        autoComplete="off"
                        className={`input-text year-input ${
                          meta.error && meta.touched ? "input-field-error" : ""
                        }`}
                        {...field}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "sizeOfVineyard",
                            e.target.value.replace(/[^0-9.,]/g, ""),
                            true
                          )
                        }
                      />
                    )}
                  </Field>
                </div>
                <Box width="110px" ml="16px">
                  <Field name="sizeUnit">
                    {({ field }) => (
                      <BsForm.Select
                        className="input-text input-height"
                        {...field}
                      >
                        <option value="ha">Hectares</option>
                        <option value="acres">Acres</option>
                      </BsForm.Select>
                    )}
                  </Field>
                </Box>
              </Box>
              <ErrorMessage
                name="sizeOfVineyard"
                className="input-error"
                component="span"
              />
              <Label
                field="elevation"
                label="Vineyard Elevation"
                className="field-spacing"
              />
              <Box display="flex">
                <div>
                  <Field name="elevation">
                    {({ field, meta }) => (
                      <input
                        type="text"
                        autoComplete="off"
                        className={`input-text year-input ${
                          meta.error && meta.touched ? "input-field-error" : ""
                        }`}
                        {...field}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "elevation",
                            e.target.value.replace(/[^0-9.,]/g, ""),
                            true
                          )
                        }
                      />
                    )}
                  </Field>
                </div>
                <Box width="110px" ml="16px">
                  <Field name="elevationUnit">
                    {({ field }) => (
                      <BsForm.Select
                        className="input-text input-height"
                        {...field}
                      >
                        <option value="meters">Meters</option>
                        <option value="feet">Feet</option>
                      </BsForm.Select>
                    )}
                  </Field>
                </Box>
              </Box>
              <ErrorMessage
                name="elevation"
                className="input-error"
                component="span"
              />

              <Box display="flex">
                <Box>
                  <Label
                    field="latitude"
                    label="Latitude"
                    className="field-spacing"/>
                  <Field placeholder="41.40338"
                         name="latitude">
                      {({ field, meta }) => (
                        <input
                          type="text"
                          autoComplete="off"
                          className={`input-text year-input ${
                            meta.error && meta.touched ? "input-field-error" : ""
                          }`}
                          {...field}
                          onChange={(e) => {
                            let input = e.target.value;
                          
                            // Allow only valid characters for latitude
                            let sanitizedInput = input.replace(/[^0-9°'".\-NSns]/, "");
                          
                            // Update the field value in formik
                            formik.setFieldValue("latitude", sanitizedInput, true);
                          }}                          
                        />
                      )}
                  </Field>
                </Box>
                <ErrorMessage
                  name="latitude"
                  className="input-error"
                  component="span"
                />
                <Box ml="16px">
                  <Label
                    field="longitude"
                    label="Longitude"
                    className="field-spacing"/>
                  <Field 
                      placeholder="2.17403"
                      name="longitude">
                      {({ field, meta }) => (
                        <input
                          type="text"
                          autoComplete="off"
                          className={`input-text year-input ${
                            meta.error && meta.touched ? "input-field-error" : ""
                          }`}
                          {...field}
                          onChange={(e) => {
                            let input = e.target.value;
                          
                            // Allow only valid characters for longitude
                            let sanitizedInput = input.replace(/[^0-9°'".\-EWew]/, "");
                            
                            // Update the field value in formik
                            formik.setFieldValue("longitude", sanitizedInput, true);
                          }}   
                        />
                      )}
                    </Field>
                </Box>
                <ErrorMessage
                  name="longitude"
                  className="input-error"
                  component="span"
                />
              </Box>
              <span className="form-fields-sub-text">
                Please submit your coordinates in decimal format. Instructions on how to determine and format your coordinates can be found <a href="https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform=Desktop" target="_blank" rel="noreferrer">here</a>
              </span>
              
              <Label
                field="varieties"
                label="Grape varieties planted*"
                className="field-spacing"
              />
              <FormInput
                name="varieties"
                as="textarea"
                rows="4"
                autoComplete="off"
                className="input-text"
              />
              <ErrorMessage
                name="varieties"
                className="input-error"
                component="span"
              />
              <span className="form-fields-sub-text">
                Please list the known grape varieties planted in the vineyard,
                separated by commas.
              </span>

              <Label
                field="ownerOrNameOfEstate"
                label="Owner / Name of estate*"
                className="field-spacing"
              />
              <FormInput
                placeholder="Name of owner or name of estate"
                name="ownerOrNameOfEstate"
                type="text"
                autoComplete="off"
                className="input-text"
              />
              <ErrorMessage
                name="ownerOrNameOfEstate"
                className="input-error"
                component="span"
              />

              <label htmlFor="websiteOfEstate" className="field-spacing">
                <span className="form-label">
                  Website of estate (if applicable)
                </span>
              </label>
              <Field
                placeholder="http://www.estatename.com"
                name="websiteOfEstate"
                type="text"
                autoComplete="off"
                className="input-text"
              />
              <ErrorMessage
                name="websiteOfEstate"
                className="input-error"
                component="span"
              />

              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(230px, 1fr))"
                gridGap="10px"
                className="field-spacing"
              >
                <Box>
                  <label htmlFor="personOfInterest">
                    <span className="form-label">Person of interest</span>
                  </label>
                  <Field
                    placeholder="Name of person"
                    name="personOfInterest"
                    type="text"
                    autoComplete="off"
                    className="input-text name-input"
                  />
                </Box>
                <Box>
                  <label htmlFor="role">
                    <span className="form-label">Their role</span>
                  </label>
                  <Field
                    placeholder="Role or title of"
                    name="role"
                    type="text"
                    autoComplete="off"
                    className="input-text name-input"
                  />
                </Box>
              </Box>
              <span className="form-fields-sub-text">
                Provide the name of a key individual associated with this
                vineyard. Most common choices might be the person who planted
                the vineyard, the name of a Viticulturalist, or the individual
                who owns and farms the vineyard.
              </span>

              <label htmlFor="photoURL" className="field-spacing">
                <span className="form-label">Photo of vineyard</span>
              </label>
              <span className="form-fields-sub-text">
                NOTE: For copyright reasons, we can only accept and include
                photographs of vineyards that are in the public domain, and
                specifically, those that have been uploaded to{" "}
                <Link
                  to="https://commons.wikimedia.org/wiki/Main_Page"
                  target="_blank"
                >
                  <span className="form-fields-sub-text-link">
                    WikiMedia Commons
                  </span>
                </Link>
                . If you would like your submission to include an image, please
                upload an image that meets the Wikimedia Commons guidelines
                using their{" "}
                <Link
                  to="https://commons.wikimedia.org/wiki/Special:UploadWizard"
                  target="_blank"
                >
                  <span className="form-fields-sub-text-link">
                    Upload Wizard
                  </span>
                </Link>
                . When you finish the process, you will be provided with a URL
                to that image’s page on Wikimedia Commons. Please paste that URL
                below.
              </span>
              <Field
                name="photoURL"
                type="text"
                autoComplete="off"
                className="input-text"
              />

              <label htmlFor="note" className="field-spacing">
                <span className="form-label">Notes</span>
              </label>
              <Field
                name="note"
                as="textarea"
                autoComplete="off"
                className="input-text notes"
              />
              <span className="form-fields-sub-text">
                Please provide any additional details that are relevant about
                this vineyard site, including but not limited to relevant
                historical points or events, notes about the site itself, etc.
              </span>

              <Label
                field="submitterName"
                label="Your name*"
                className="field-spacing"
              />
              <span>
                <FormInput
                  name="submitterName"
                  type="text"
                  size="32"
                  autoComplete="off"
                  className="input-text"
                />
              </span>
              <ErrorMessage
                name="submitterName"
                className="input-error"
                component="span"
              />

              <Label
                field="submitterEmail"
                label="Your e-mail address*"
                className="field-spacing"
              />
              <span>
                <FormInput
                  name="submitterEmail"
                  type="text"
                  size="32"
                  autoComplete="off"
                  className="input-text"
                />
              </span>
              <ErrorMessage
                name="submitterEmail"
                className="input-error"
                component="span"
              />

              <span>
                <button type="submit" className="button field-spacing">
                  Submit
                </button>
              </span>
              <Loading loading={submitting} />
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};